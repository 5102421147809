import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

const PrivacyPage = ({ data }) => (
  <Layout>
    <div className="container">
          <div className="row">
            <div className="col-lg-9 mb-5 team-opleidingen">
              <h1 className="mb-3">{data.strapiPrivacy.titel}</h1>
              <ReactMarkdown source={data.strapiPrivacy.tekst} />
            </div>
          </div>
    </div>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  query PrivacyQuery {
    strapiPrivacy {
      titel
      tekst
    }
  }
`